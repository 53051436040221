export interface LiveStream {
    id: string
    name: string
    icon: string
    order: number
    url: string[]
    isActive: boolean
    overrideTitleString: string
    liveStreamType: any
    complementaryLiveStreamId?: string | null
}

export interface LiveStreamUploadImage {
    imageUrl: string
}

export enum LiveStreamType {
    Undefined = 'undefined',
    HLS = 'hls',
    Advert = 'advert',
    ICE = 'ice'
}
