import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Environment } from '@fabrik/common'

import { AccessControlDataService } from './access-control.data.service'
import { AdvertiserDataService } from './advertiser-data.service'
import { AdvertisingDataService } from './advertising-data.service'
import { AuthDataService } from './auth-data.service'
import { CampaignCastDataService } from './campaign-cast.service'
import { CampaignDataService } from './campaign-data.service'
import { ChatGroupDataService } from './chat-group-data.service'
import { DocumentDataService } from './document-data.service'
import { FeedDataService } from './feed-data.service'
import { LiveDataService } from './live-data.service'
import { LiveStreamDataService } from './live-stream-data.service'
import { MatomoDataService } from './matomo-data.service'
import { MemberDataService } from './member-data.service'
import { MetricsDataService } from './metrics-data.service'
import { OAuthDataService } from './oauth-data.service'
import { PanicButtonDataService } from './panic-button-data.service'
import { ProfileFieldDataService } from './profile-field-data.service'
import { RegistrationsDataService } from './registrations-data.service'
import { ReportDataService } from './report-data.service'
import { RewindDataService } from './rewind-data.service'
import { SessionDataService } from './session-data.service'
import { SmashboardDataService } from './smashboard-data.service'
import { StreamingAudioAdvertisingDataService } from './streaming-audio-advertising.service'
import { DirectoryDataService } from './service-directory-data-service'
import { SupportCategoryDataService } from './support-categories-data.service'
import { FreshDeskDataService } from './fresh-desk-data-service'
import { LikeDislikeDataService } from './like-dislike.data.services'
import { SmartInventoryDataService } from './smart-inventory.service'
import { ContentFeedDataService } from './content-feed-data.service'
import { CampaignCastReportDataService } from './campaign-cast-report.service'
import { FormDataService } from './forms-data.service'
import { CampaignBookingRequestService } from './booking-data.service'
import { SmartAdvertisersDataService } from './smartAdvertisers-data.service'
import { ProductSubscriptionsDataService } from './product-subscriptions-data.service'
import { YoutubeChannelDataService } from './youtube-channel-data.service'
import { TenantDataService } from './audience-data.service'
import { AppDisplayNameDataService } from './app-display-name.data.service'
import { AcquisitionsDataService } from './acquisitions-data.service'
import { EchocastDataService } from './echocast.data.service'
import { MembersReportDataService } from './members-report-data.service'
import { EsgDataService } from './esg-data.service'
import { EventDataService } from './event-data.service'

@Injectable()
export class DataService {
    advertiser: AdvertiserDataService
    advertising: AdvertisingDataService
    accessControl: AccessControlDataService
    auth: AuthDataService
    campaign: CampaignDataService
    chatGroup: ChatGroupDataService
    document: DocumentDataService
    directory: DirectoryDataService
    feed: FeedDataService
    live: LiveDataService
    livestream: LiveStreamDataService
    matomo: MatomoDataService
    member: MemberDataService
    metrics: MetricsDataService
    oauth: OAuthDataService
    profileField: ProfileFieldDataService
    panicButton: PanicButtonDataService
    registrations: RegistrationsDataService
    report: ReportDataService
    rewind: RewindDataService
    smartCampaign: CampaignCastDataService
    session: SessionDataService
    smashboard: SmashboardDataService
    streamingAudioAdvertising: StreamingAudioAdvertisingDataService
    supportCategory: SupportCategoryDataService
    freshDesk: FreshDeskDataService
    likeDislike: LikeDislikeDataService
    inventory: SmartInventoryDataService
    contentFeed: ContentFeedDataService
    campaignCastReports: CampaignCastReportDataService
    campaignBookingRequests: CampaignBookingRequestService
    forms: FormDataService
    smartadvertisers: SmartAdvertisersDataService
    video: YoutubeChannelDataService
    productSubscriptions: ProductSubscriptionsDataService
    audience: TenantDataService
    displayName: AppDisplayNameDataService
    acquisitionsDataService: AcquisitionsDataService
    echocast: EchocastDataService
    membersReport: MembersReportDataService
    esgDataService: EsgDataService
    tenantEventDataService: EventDataService

    constructor(private environment: Environment, private httpClient: HttpClient) {
        this.advertiser = new AdvertiserDataService(environment, httpClient)
        this.advertising = new AdvertisingDataService(environment, httpClient)
        this.accessControl = new AccessControlDataService(environment, httpClient)
        this.auth = new AuthDataService(environment, httpClient)
        this.campaign = new CampaignDataService(environment, httpClient)
        this.chatGroup = new ChatGroupDataService(environment, httpClient)
        this.document = new DocumentDataService(environment, httpClient)
        this.directory = new DirectoryDataService(environment, httpClient)
        this.feed = new FeedDataService(environment, httpClient)
        this.live = new LiveDataService(environment, httpClient)
        this.livestream = new LiveStreamDataService(environment, httpClient)
        this.matomo = new MatomoDataService(environment, httpClient)
        this.member = new MemberDataService(environment, httpClient)
        this.metrics = new MetricsDataService(environment, httpClient)
        this.oauth = new OAuthDataService(environment, httpClient)
        this.panicButton = new PanicButtonDataService(environment, httpClient)
        this.profileField = new ProfileFieldDataService(environment, httpClient)
        this.registrations = new RegistrationsDataService(environment, httpClient)
        this.rewind = new RewindDataService(environment, httpClient)
        this.report = new ReportDataService(environment, httpClient)
        this.smartCampaign = new CampaignCastDataService(environment, httpClient)
        this.session = new SessionDataService(environment, httpClient)
        this.smashboard = new SmashboardDataService(environment, httpClient)
        this.streamingAudioAdvertising = new StreamingAudioAdvertisingDataService(environment, httpClient)
        this.supportCategory = new SupportCategoryDataService(environment, httpClient)
        this.freshDesk = new FreshDeskDataService(environment, httpClient)
        this.likeDislike = new LikeDislikeDataService(environment, httpClient)
        this.inventory = new SmartInventoryDataService(environment, httpClient)
        this.contentFeed = new ContentFeedDataService(environment, httpClient)
        this.campaignCastReports = new CampaignCastReportDataService(environment, httpClient)
        this.campaignBookingRequests = new CampaignBookingRequestService(environment, httpClient)
        this.forms = new FormDataService(environment, httpClient)
        this.smartadvertisers = new SmartAdvertisersDataService(environment, httpClient)
        this.video = new YoutubeChannelDataService(environment, httpClient)
        this.productSubscriptions = new ProductSubscriptionsDataService(environment, httpClient)
        this.audience = new TenantDataService(environment, httpClient)
        this.displayName = new AppDisplayNameDataService(environment, httpClient)
        this.acquisitionsDataService = new AcquisitionsDataService(environment, httpClient)
        this.echocast = new EchocastDataService(environment, httpClient)
        this.membersReport = new MembersReportDataService(environment, httpClient)
        this.esgDataService = new EsgDataService(environment, httpClient)
        this.tenantEventDataService = new EventDataService(environment, httpClient)
    }
}
