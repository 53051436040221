export enum TenantEventStatus{
    Active,
    Removed
}
export enum TenantEventPriority{
    Low,
    Standard,
    High
}
export enum TenantEventTTL{
    Day,
    Week,
    Month
}
export enum TenantEventType{
    Message
}
