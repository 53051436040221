import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Environment, utils, PaginatedList } from '@fabrik/common';
import { Observable } from 'rxjs';
import { TenantEvent, TenantEventSearch, TenantEventViewModel } from '../models/TenantEvent';
import { RollupPeriod } from '../enums/rollup-period.enum';
import { TenantEventStats } from '../models/Metrics';
import { TimeUtils } from '../utils/time-utils';

// @Injectable({
//   providedIn: 'root'
// })
export class EventDataService {

  constructor(private environment: Environment, private http: HttpClient) { }

  byId(tenantId: string, eventId: string): Observable<TenantEvent>{
    return this.http.get<TenantEvent>(`${this.environment.appApiHost}/api/${tenantId}/admin/tenantevents/${eventId}`)
  }

  list(tenantId: string, payload: TenantEventSearch, args:{page?: number; limit?: number}): Observable<PaginatedList<TenantEventViewModel>>{
    let params = new HttpParams()
    Object.keys(args).forEach(key => {
      switch (key) {
          case 'page':
              if (utils.notNullOrUndefined(args.page)) {
                  params = params.set('page', (args.page - 1).toString())
              }
              break

          default:
              if (utils.notNullOrUndefined(args[key])) {
                  params = params.set(key, args[key])
              }
      }
  })
    return this.http.post<PaginatedList<TenantEventViewModel>>(`${this.environment.appApiHost}/api/${tenantId}/admin/tenantevents/search`, payload, {params})
  }
  update(tenantId: string, payload: TenantEvent): Observable<TenantEvent>{
    return this.http.put<TenantEvent>(`${this.environment.appApiHost}/api/${tenantId}/admin/tenantEvents/${payload.id}`, payload)
  }
  create(tenantId: string, payload: TenantEvent): Observable<TenantEvent>{
    return this.http.post<TenantEvent>(`${this.environment.appApiHost}/api/${tenantId}/admin/tenantEvents`, payload)
  }
  delete(tenantId: string, tenantEventId: string): Observable<TenantEvent>{
    return this.http.delete<TenantEvent>(`${this.environment.appApiHost}/api/${tenantId}/admin/tenantEvents/${tenantEventId}`)
  }
  getEventsStatistics(tenantId: string,period: RollupPeriod, startDate: Date, endDate: Date, ): Observable<TenantEventStats[]> {
        return this.http.get<TenantEventStats[]>(`${this.environment.metricsApiHost}/tenantstats/${tenantId}/Events?rollupPeriod=${period}&startDate=${TimeUtils.recreateDateAsUTC(startDate).toISOString()}&endDate=${TimeUtils.recreateDateAsUTC(endDate).toISOString()}`)
  } 
}
